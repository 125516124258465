import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./components/Header/Header";
import Chooser from "./components/Chooser/Chooser";
import Display from "./components/Display/Display";
import { STYLE_IMAGE } from "./constants";
import styles from "./App.module.scss";

function App() {
  useEffect(() => {
    handleWakeUpModel();
  }, []);

  const [selectedContentImage, setSelectedContentImage] = useState(null);
  const [selectedStyleImage, setSelectedStyleImage] = useState(null);
  const [scaleValue, setScaleValue] = useState(0.3);
  const [composer, setComposer] = useState(0);
  const [currentMode, setCurrentMode] = useState(STYLE_IMAGE);

  const handleSelectContentimage = ({ contentImageList, id }) => {
    const contentImage = contentImageList.find((item) => item.id === id);
    setSelectedContentImage(contentImage);
  };

  const handleSetUploadedContentImage = (contentImage) =>
    setSelectedContentImage({ url: contentImage });

  const handleSelectStyleimage = ({ styleImageList, id }) => {
    const styleImage = styleImageList.find((item) => item.id === id);
    setSelectedStyleImage(styleImage);
  };

  const handleSetScaleValue = (value) => setScaleValue(value);
  const handleSelecComposer = (e) => setComposer(parseInt(e.target.value));
  const handleWakeUpModel = () =>
    axios({
      method: "post",
      url:
        "https://us-central1-piccollage-ml-dev.cloudfunctions.net/style_blending",
      data: {
        wake_up: 1,
      },
    });

  return (
    <div className={styles.root}>
      <Header currentMode={currentMode} setCurrentMode={setCurrentMode} />
      <Chooser
        currentMode={currentMode}
        handleSelectContentimage={handleSelectContentimage}
        handleSelectStyleimage={handleSelectStyleimage}
        scaleValue={scaleValue}
        handleSetScaleValue={handleSetScaleValue}
        composer={composer}
        handleSelecComposer={handleSelecComposer}
        handleSetUploadedContentImage={handleSetUploadedContentImage}
      />
      <Display
        contentImage={selectedContentImage}
        styleImage={selectedStyleImage}
        scaleValue={scaleValue}
        composer={composer}
      />
    </div>
  );
}

export default App;
