import React, { useState, useMemo } from "react";
import axios from "axios";
import { Button, useToast } from "@chakra-ui/core";
import styles from "./Display.module.scss";

let active = false;
let currentX;
let currentY;
let initialX;
let initialY;
let xOffset = 0;
let yOffset = 0;

const Display = ({ contentImage, styleImage, scaleValue, composer }) => {
  const contentImageRef = React.createRef();
  const styleImageContainerRef = React.createRef();
  const [composedImage, setComposedImage] = useState("");
  const [contentImagePosition, setContentImagePosition] = useState({
    x: 0,
    y: 0,
  });
  const [isComposing, setIsComposing] = useState(false);
  const toast = useToast();

  const dragStart = (e) => {
    if (e.type === "touchstart") {
      initialX = e.touches[0].clientX - xOffset;
      initialY = e.touches[0].clientY - yOffset;
    } else {
      initialX = e.clientX - xOffset;
      initialY = e.clientY - yOffset;
    }

    active = true;

    if (e.target === contentImageRef.current) {
      active = true;
    }
  };

  const dragEnd = (e) => {
    initialX = currentX;
    initialY = currentY;

    active = false;
  };

  const drag = (e) => {
    if (active) {
      e.preventDefault();
      if (e.type === "touchmove") {
        currentX = e.touches[0].clientX - initialX;
        currentY = e.touches[0].clientY - initialY;
      } else {
        currentX = Math.min(
          Math.max(e.clientX - initialX, 0),
          styleImageContainerRef.current.offsetWidth -
            contentImageRef.current.offsetWidth
        );
        currentY = Math.min(
          Math.max(e.clientY - initialY, 0),
          styleImageContainerRef.current.offsetHeight -
            contentImageRef.current.offsetHeight
        );
      }

      xOffset = currentX;
      yOffset = currentY;

      setContentImagePosition({
        x: currentX,
        y: currentY,
      });
    }
  };

  const contentImageStyle = useMemo(() => {
    return {
      width: `${scaleValue * 100}%`,
      transform: `translate(${contentImagePosition.x}px, ${contentImagePosition.y}px)`,
    };
  }, [scaleValue, contentImagePosition.x, contentImagePosition.y]);

  const getBase64Image = (url) => {
    return new Promise((resolve, reject) => {
      try {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
          const canvas = document.createElement("CANVAS");
          const ctx = canvas.getContext("2d");
          let dataURL;
          canvas.height = this.naturalHeight;
          canvas.width = this.naturalWidth;
          ctx.drawImage(this, 0, 0);
          dataURL = canvas.toDataURL();
          resolve(dataURL.replace(/^data:image.+;base64,/, ""));
        };
        img.src = url;
      } catch (error) {
        reject(error);
      }
    });
  };

  const getComposedImage = () => {
    setIsComposing(true);

    const basicInfo = {
      style_image_id: styleImage.id,
      ratio:
        contentImageRef.current.offsetWidth /
        contentImageRef.current.naturalWidth,
      x1: contentImagePosition.x / styleImageContainerRef.current.offsetWidth,
      y1: contentImagePosition.y / styleImageContainerRef.current.offsetHeight,
      x2:
        (contentImagePosition.x + contentImageRef.current.offsetWidth) /
        styleImageContainerRef.current.offsetWidth,
      y2:
        (contentImagePosition.y + contentImageRef.current.offsetHeight) /
        styleImageContainerRef.current.offsetHeight,
      alg: composer,
      wake_up: 0,
    };

    getBase64Image(contentImage.url).then((base64Url) => {
      axios({
        method: "post",
        url:
          "https://us-central1-piccollage-ml-dev.cloudfunctions.net/style_blending",
        data: {
          content_image: base64Url,
          ...basicInfo,
        },
      })
        .then((res) => {
          setComposedImage(res.data.pred);
          setIsComposing(false);
          toast({
            position: "top-right",
            title: "Success",
            description: "Check out your creativity",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        })
        .catch((err) => {
          console.error(err);
          setIsComposing(false);
          toast({
            position: "top-right",
            title: "An error occurred.",
            description: err.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    });
  };

  const isBothImagesSelected = contentImage && styleImage;

  return (
    <div className={styles.root}>
      <div className={styles.actionBar}>
        <Button
          variantColor="teal"
          onClick={getComposedImage}
          isLoading={isComposing}
          loadingText="Composing"
          isDisabled={!isBothImagesSelected}
        >
          Compose
        </Button>
      </div>
      <div className={styles.imageContainer}>
        <div className={styles.result}>
          <h3 className={styles.title}>Result</h3>
          {composedImage && (
            <img
              src={`data:image/jpeg;base64,${composedImage}`}
              alt="item"
              className={styles.composedImage}
            />
          )}
        </div>
        <div className={styles.editor}>
          <h3 className={styles.title}>Editor</h3>
          <div
            className={styles.styleImageFrame}
            ref={styleImageContainerRef}
            onMouseDown={dragStart}
            onMouseMove={drag}
            onMouseUp={dragEnd}
          >
            {styleImage && (
              <img
                src={styleImage.url}
                className={styles.styleImage}
                alt="selected style"
              />
            )}
            {contentImage && (
              <img
                ref={contentImageRef}
                src={contentImage.url}
                className={styles.contentImage}
                style={contentImageStyle}
                alt="selected style"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Display;
