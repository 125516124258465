import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import cx from "classnames";
import Img from "react-image";
import {
  Spinner,
  FormControl,
  FormLabel,
  Select,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Input,
  Button
} from "@chakra-ui/core";
import initFirebase from "../../utils/initFirebase";
import { CONTENT_IMAGE, STYLE_IMAGE, MANIPULATOR } from "../../constants";
import styles from "./Chooser.module.scss";

const Chooser = ({
  currentMode,
  handleSelectContentimage,
  handleSelectStyleimage,
  scaleValue,
  handleSetScaleValue,
  composer,
  handleSelecComposer,
  handleSetUploadedContentImage
}) => {
  const [contentImageList, setContentImageList] = useState(null);
  const [styleImageList, setStyleImageList] = useState(null);

  useEffect(() => {
    initFirebase();
    getStyleImages();
    getContentImages();
  }, []);

  const containerRef = React.createRef();

  const getContentImages = () => {
    const db = firebase.firestore();

    db.collection("contentImages")
      .orderBy("id")
      .get()
      .then(function(querySnapshot) {
        const result = [];

        querySnapshot.forEach(function(doc) {
          result.push(doc.data());
        });

        setContentImageList(result);
      });
  };

  const getStyleImages = () => {
    const db = firebase.firestore();

    db.collection("styleImages")
      .orderBy("id")
      .get()
      .then(function(querySnapshot) {
        const result = [];

        querySnapshot.forEach(function(doc) {
          result.push(doc.data());
        });

        setStyleImageList(result);
      });
  };

  const handlePreviewUploadedContentImage = e => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();

      reader.onload = function(e) {
        handleSetUploadedContentImage(e.target.result);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div className={styles.root} ref={containerRef}>
      {currentMode === STYLE_IMAGE && (
        <>
          <h3 className={styles.header}>Style Image</h3>
          <div className={styles.styleImageWrapper}>
            {styleImageList &&
              styleImageList.map(styleImage => (
                <div className={styles.imageFrame} key={styleImage.id}>
                  <Img
                    src={styleImage.url}
                    alt={styleImage.id}
                    className={styles.styleImage}
                    loader={
                      <Spinner color="#f8aba8" className={styles.loader} />
                    }
                    onClick={() =>
                      handleSelectStyleimage({
                        styleImageList,
                        id: styleImage.id
                      })
                    }
                  />
                </div>
              ))}
          </div>
        </>
      )}

      {currentMode === CONTENT_IMAGE && (
        <>
          <h3 className={styles.header}>Content Image</h3>
          <FormControl className={styles.uploadContentImageForm}>
            <Button
              as="label"
              htmlFor="uploadContentImage"
              variantColor="teal"
              className={styles.uploadContentImageLabel}
            >
              Upload
            </Button>
            <Input
              type="file"
              id="uploadContentImage"
              className={styles.uploadContentImageInput}
              onChange={handlePreviewUploadedContentImage}
            />
          </FormControl>

          <div className={styles.contentImageWrapper}>
            {contentImageList &&
              contentImageList.map(contentImage => {
                return (
                  <div className={styles.imageFrame} key={contentImage.id}>
                    <Img
                      src={contentImage.url}
                      alt={contentImage.id}
                      className={styles.contentImage}
                      loader={
                        <Spinner color="#f8aba8" className={styles.loader} />
                      }
                      onClick={() =>
                        handleSelectContentimage({
                          contentImageList,
                          id: contentImage.id
                        })
                      }
                    />
                  </div>
                );
              })}
          </div>
        </>
      )}

      {currentMode === MANIPULATOR && (
        <>
          <h3 className={styles.header}>Manipulator</h3>
          <FormControl className={styles.formField}>
            <FormLabel htmlFor="composer" className={styles.formLabel}>
              Composer
            </FormLabel>
            <Select
              id="composer"
              placeholder="Select composer"
              value={composer}
              onChange={handleSelecComposer}
              borderColor="#CBD5E0"
              focusBorderColor="#CBD5E0"
              color="#666"
              className={styles.composerSelector}
            >
              <option value="0">composer 0</option>
              <option value="1">composer 1</option>
              <option value="2">composer 2</option>
            </Select>
          </FormControl>
          <FormControl className={cx(styles.formField, styles.scaleBarField)}>
            <FormLabel htmlFor="scaleBar" className={styles.formLabel}>
              Scale
            </FormLabel>
            <Slider
              value={scaleValue}
              onChange={handleSetScaleValue}
              defaultValue={0.3}
              id="scaleBar"
              min={0.1}
              max={1}
              step={0.01}
              color="gray"
              className={styles.scaleSlider}
            >
              <SliderTrack />
              <SliderFilledTrack />
              <SliderThumb
                fontSize="sm"
                width="32px"
                height="20px"
                children={scaleValue}
              />
            </Slider>
          </FormControl>
        </>
      )}
    </div>
  );
};

export default Chooser;
