import React from "react";
import { MdPhoto, MdPhotoFilter, MdAspectRatio } from "react-icons/md";
import HeaderItem from "./HeaderItem";
import { CONTENT_IMAGE, STYLE_IMAGE, MANIPULATOR } from "../../constants";
import styles from "./Header.module.scss";

const Header = ({ currentMode, setCurrentMode }) => {
  const items = [
    {
      name: STYLE_IMAGE,
      icon: MdPhoto,
      isActive: currentMode === STYLE_IMAGE,
      handler() {
        setCurrentMode(STYLE_IMAGE);
      }
    },
    {
      name: CONTENT_IMAGE,
      icon: MdPhotoFilter,
      isActive: currentMode === CONTENT_IMAGE,
      handler() {
        setCurrentMode(CONTENT_IMAGE);
      }
    },
    {
      name: MANIPULATOR,
      icon: MdAspectRatio,
      isActive: currentMode === MANIPULATOR,
      handler() {
        setCurrentMode(MANIPULATOR);
      }
    }
  ];

  return (
    <header className={styles.root}>
      {items.map(item => (
        <HeaderItem key={item.name} {...item} />
      ))}
    </header>
  );
};

export default Header;
