import React from "react";
import { Box } from "@chakra-ui/core";
import cx from "classnames";
import styles from "./HeaderItem.module.scss";

const ICON_SIZE = "32px";

const HeaderItem = ({ icon, isActive, handler }) => {
  const wrapperStyle = cx([styles.iconWrapper], {
    [styles.active]: isActive
  });

  return (
    <div className={wrapperStyle} onClick={handler}>
      <div className={styles.iconArea}>
        <Box as={icon} size={ICON_SIZE} className={styles.icon} />
      </div>
    </div>
  );
};

export default HeaderItem;
